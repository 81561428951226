<template>
  <section class="content">
    <div class="text">
      <span v-html="page.main_text"></span>
    </div>
    <div class="caravans">
      <div class="ca" :key="caravan._id" v-for="caravan in caravans">
        <div class="gallery">
          <img
            v-if="caravan.images && caravan.images.length"
            class="gallery-main"
            :id="'img' + caravan._id"
            :alt="caravan.name"
            :src="caravan.images[0].image"
          />
          <div class="gallery-thumbs">
            <img
              @click.prevent="showImage('img' + caravan._id, img)"
              :key="img._id"
              :alt="caravan.name"
              :src="img.image"
              v-for="img in caravan.images"
            />
          </div>
        </div>
        <div class="ca-name">{{ caravan.name }}</div>
        <div class="ca-stats">
          <div class="ca-location"><font-awesome-icon class="icon" icon="map-marker-alt" /> {{ caravan.location }}</div>
          <div class="ca-beds"><font-awesome-icon class="icon" icon="bed" /> {{ caravan.bedrooms }}</div>
          <div class="ca-sleeps"><font-awesome-icon class="icon" icon="male" /> {{ caravan.sleeps }}</div>
        </div>
        <div class="ca-description" v-html="caravan.description"></div>
        <div class="ca-more">
          <a :href="caravan.url" target="_blank" rel="noreferrer"
            >View more information here <font-awesome-icon class="icon" icon="external-link-alt"
          /></a>
        </div>
        <button class="ca-book" @click.prevent="showBooking(caravan)">
          <font-awesome-icon class="icon" icon="calendar-check" /> Book Online
        </button>
      </div>
    </div>
    <div class="text dl">
      <downloads />
    </div>
    <modal :scrollable="true" height="auto" name="booking" width="90%">
      <a @click.prevent="$modal.hide('booking')" class="modal-close" href>
        <font-awesome-icon icon="times" />
      </a>
      <div class="modal-grid">
        <div v-if="!emailsuccess">
          <h2>1. Choose a check-in date for your stay in the {{ caToShow.name }}</h2>
          <v-date-picker
            is-expanded
            :columns="$screens({ default: 1, lg: 2 })"
            :attributes="attrs"
            :disabled-dates="{}"
            :available-dates="availableDates"
            :min-date="minDate"
            :max-date="maxDate"
            v-model="form.date"
            @input="dateSelected"
          />
          <h2>2. Confirm the length of your stay</h2>
          <template v-if="!form.date">
            <p class="checkin spaced">Select a check-in date from the calendar above <span class="req">*</span></p>
          </template>
          <template v-if="form.date && !price">
            <p class="checkin spaced">Date not available</p>
          </template>
          <template v-if="price">
            <p class="checkin">Check-in on {{ dateFormat(form.date) }}</p>
            <div
              v-if="form.date.getDay() == 5"
              class="nights"
              @click.prevent="form.nights = 3"
              :class="{ sel: form.nights == 3 }"
            >
              <strong>3 nights</strong> &pound;{{
                price.price_friday_3.toFixed ? price.price_friday_3.toFixed(2) : "0.00"
              }}
            </div>
            <div
              v-if="form.date.getDay() == 1"
              class="nights"
              @click.prevent="form.nights = 4"
              :class="{ sel: form.nights == 4 }"
            >
              <strong>4 nights</strong> &pound;{{
                price.price_monday_4.toFixed ? price.price_monday_4.toFixed(2) : "0.00"
              }}
            </div>
            <div
              v-if="form.date.getDay() == 5 && sevenNightsAvailable(form.date)"
              class="nights"
              @click.prevent="form.nights = 7"
              :class="{ sel: form.nights == 7 }"
            >
              <strong>7 nights</strong> &pound;{{
                price.price_friday_7.toFixed ? price.price_friday_7.toFixed(2) : "0.00"
              }}
            </div>
          </template>
          <h2>3. Complete the booking request form</h2>
          <div class="form">
            <div class="form-row">
              <label class="form-label">Name <span class="req">*</span></label>
              <input class="form-text" type="text" v-model="form.name" />
            </div>
            <div class="form-row">
              <label class="form-label">Collar Number <span class="req">*</span></label>
              <input class="form-text" type="text" v-model="form.collar" />
            </div>
            <div class="form-row">
              <label class="form-label">Email <span class="req">*</span></label>
              <input class="form-text" type="email" v-model="form.email" />
            </div>
            <div class="form-row">
              <label class="form-label">Telephone <span class="req">*</span></label>
              <input class="form-text" type="text" v-model="form.telephone" />
            </div>
            <div class="form-row">
              <label class="form-label">Message</label>
              <textarea class="form-text" v-model="form.message"></textarea>
            </div>
            <div class="form-error" v-show="errormessage">{{ errormessage }}</div>
            <button class="ca-book" :disabled="!!loading" @click="submitRequest">
              <font-awesome-icon icon="check" /> Submit Request
            </button>
          </div>
        </div>
        <div v-else>
          <div class="form-success">Thanks, your booking request has been sent! We'll be in touch soon.</div>
        </div>
      </div>
    </modal>
  </section>
</template>

<script>
import api from "@/services/api"
import dateUtil from "date-and-time"

export default {
  metaInfo() {
    return {
      title: this.$store.state.page.title || "South Yorkshire Police Federation",
      meta: [
        { name: "description", content: this.$store.state.page.meta_desc },
        { name: "keywords", content: this.$store.state.page.meta_keywords }
      ]
    }
  },
  created() {
    api.getPageByURL(this.$route.path)

    api.getBookedDates().then((dates) => {
      var sevens = []
      dates.forEach((date) => {
        date.dateString = date.date
        date.date = dateUtil.parse(date.date, "DD/MM/YYYY").getTime()

        if (date.nights == 7) sevens.push(date)

        if (
          (date.payment_status == "Paid" || date.payment_status == "Deposit Paid") &&
          date.booking_status != "Cancelled"
        )
          this.paiddates.push(date)
      })

      sevens.forEach((s) => {
        var date = new Date(s.date)
        dates.push({ caravan_id: s.caravan_id, date: date.setDate(date.getDate() + 3) })
      })

      this.bookeddates = dates
    })
  },
  data() {
    return {
      form: { nights: 0 },
      caToShow: { name: "", caravan_id: "" },
      minDate: null,
      maxDate: null,
      errormessage: "",
      emailsuccess: "",
      bookeddates: [],
      paiddates: [],
      loading: false
    }
  },
  computed: {
    price() {
      if (!this.form.date) return null
      if (!this.caToShow.prices) return null

      var price = this.caToShow.prices.filter((p) => p.week_commencing <= this.form.date.getTime() / 1000)

      if (!price.length) return null

      return price[0]
    },
    availableDates() {
      if (!this.caToShow.prices) return []

      var now = new Date().getTime()

      var available = this.caToShow.prices

      var dates = []
      available.forEach((p) => {
        var date = new Date(p.week_commencing * 1000)

        var monday = this.getNextDayOfWeek(date, 1)
        var friday = this.getNextDayOfWeek(date, 5)

        if (monday.getTime() > now)
          if (!this.bookeddates.filter((d) => d.caravan_id == this.caToShow._id && d.date == monday.getTime()).length)
            dates.push(JSON.parse(JSON.stringify(monday)))
        if (friday.getTime() > now)
          if (!this.bookeddates.filter((d) => d.caravan_id == this.caToShow._id && d.date == friday.getTime()).length)
            dates.push(JSON.parse(JSON.stringify(friday)))
      })

      return dates
    },
    paidDates() {
      if (!this.caToShow.prices) return []

      var dates = []
      this.paiddates.forEach((p) => {
        if (p.caravan_id == this.caToShow._id) {
          dates.push(JSON.parse(JSON.stringify(new Date(p.date))))

          if (p.nights == 7) {
            console.log(p)
            var nextMonday = new Date(p.date)
            nextMonday = nextMonday.setDate(nextMonday.getDate() + 3)
            dates.push(JSON.parse(JSON.stringify(new Date(nextMonday))))
          }
        }
      })

      return dates
    },
    attrs() {
      return [
        {
          highlight: {
            color: "green",
            fillMode: "light"
          },
          dates: this.availableDates,
          popover: {
            label: "Available - click to choose the length of your stay"
          }
        },
        {
          highlight: {
            color: "red",
            fillMode: "light"
          },
          dates: this.paidDates,
          popover: {
            label: "Booked"
          }
        }
      ]
    },
    page() {
      return this.$store.state.page
    },
    caravans() {
      return this.$store.state.caravans || []
    }
  },
  mounted() {
    this.minDate = new Date()
    this.maxDate = new Date(new Date().setFullYear(new Date().getFullYear() + 1))
  },
  methods: {
    sevenNightsAvailable(d) {
      if (!d) return false

      if (d.getDay() !== 5) return false

      var nextMonday = new Date(d.getFullYear(), d.getMonth(), d.getDate())
      nextMonday.setDate(nextMonday.getDate() + 3)

      return !this.bookeddates.filter((d) => d.date == nextMonday.getTime() && d.caravan_id == this.caToShow._id).length
    },
    getNextDayOfWeek(date, dayOfWeek) {
      var resultDate = new Date(date.getTime())

      resultDate.setDate(date.getDate() + ((7 + dayOfWeek - date.getDay()) % 7))

      return resultDate
    },
    dateSelected() {
      this.form.nights = 0
    },
    dateFormat(d) {
      return dateUtil.format(d, "DD/MM/YYYY")
    },
    submitRequest: function () {
      this.errormessage = ""
      this.emailsuccess = ""

      if (!this.form.date) return (this.errormessage = "Please select a check in date from the calendar")

      if (!this.form.nights)
        return (this.errormessage =
          "Please confirm the length of your stay by clicking on the desired number of nights")

      if (!this.form.name || !this.form.collar || !this.form.email || !this.form.telephone)
        return (this.errormessage = "Please fill in all required information")

      this.loading = true

      this.form.day = this.form.date.getDate()
      this.form.month = this.form.date.getMonth()
      this.form.year = this.form.date.getFullYear()

      api
        .requestBooking(this.form)
        .then(() => (this.emailsuccess = "Thank you for you request"))
        .catch(() => (this.errormessage = "There has been an error"))
        .finally(() => (this.loading = false))
    },
    showImage: function (target, src) {
      document.getElementById(target).src = src.image
    },
    showBooking(ca) {
      this.caToShow = ca

      if (this.caToShow.prices) this.caToShow.prices.sort((a, b) => b.week_commencing - a.week_commencing)

      this.form.caravan = this.caToShow.name
      this.form.caravan_id = this.caToShow._id
      this.$modal.show("booking")
    }
  }
}
</script>

<style scoped>
.caravans {
  padding: 0 2rem;
}

.ca {
  background: #eee;
  border-radius: 1rem;
  margin-top: 2rem;
  padding: 2rem 3rem;
  font-size: 1.8rem;
}

.ca >>> ul {
  margin-left: 2rem;
}

.ca-name {
  font-size: 2.5rem;
  color: #203165;
  font-weight: bold;
}

.ca-description {
  font-size: 1.6rem;
}

.ca-stats {
  color: #db411d;
  margin-bottom: 1rem;
  margin-top: 0.5rem;
}

.req {
  color: #db411d;
}

.ca-stats > div {
  display: inline-block;
  padding-right: 3rem;
}
.ca-stats svg,
.ca-book svg {
  margin-right: 0.5rem;
}

.ca-more a svg {
  margin-left: 0.5rem;
  opacity: 0.7;
  font-size: 1.4rem;
}
.ca-more {
  padding-top: 1rem;
}
.ca-more a {
  font-size: 1.6rem;
  border-bottom: 1px dotted #ccc;
  transition: all 0.3s;
}
.ca-more a:hover {
  color: #db411d;
}

.ca-book {
  font-size: 2rem;
  background: #088d0f;
  color: white;
  padding: 1rem 2rem;
  border: 0;
  border-radius: 1rem;
  margin-top: 2rem;
  margin-bottom: 1rem;
  cursor: pointer;
  transition: all 0.3s;
}

.ca-book:hover {
  background: #06810c;
}

.gallery {
  float: right;
  width: 50%;
  max-width: 570px;
  margin-left: 5%;
  position: relative;
  margin-top: 2rem;
}

.gallery img {
  border-radius: 1rem;
}

.gallery-thumbs {
  width: 100%;
}

.gallery-thumbs img {
  width: 13%;
  border-radius: 5px;
  margin: 1rem 0.5rem;
  cursor: pointer;
  transition: all 0.3s;
}
.gallery-thumbs img:hover {
  transform: scale(1.05);
}

.modal-grid h2 {
  font-size: 2rem;
  color: #203165;
  padding-bottom: 2rem;
}

.form {
  padding-top: 0;
}

.form-text {
  border: 1px solid #e2e2e2;
}

.checkin {
  font-size: 1.5rem;
}

.checkin.spaced {
  margin-bottom: 2rem;
}

.nights {
  margin-top: 2rem;
  margin-bottom: 3rem;
  font-size: 2rem;
  display: inline-block;
  margin-right: 2rem;
  border: 1px solid #f5f5f5;
  padding: 1rem 1.5rem;
  cursor: pointer;
  border-radius: 1rem;
  color: #666;
}

.nights:hover {
  color: #333;
  border: 1px solid #c6f6d5;
}

.nights strong {
  display: inline-block;
  margin-right: 0.5rem;
}

.sel,
.sel:hover {
  color: green;
  border: 1px solid #c6f6d5;
  background: #c6f6d5;
}

.form-error,
.form-success {
  font-size: 1.8rem;
}

@media screen and (min-width: 1670px) {
  .ca {
    min-height: 590px;
  }
}

@media screen and (max-width: 750px) {
  .gallery {
    width: 100%;
    margin-left: 0;
    float: none;
    margin-top: 1rem;
  }
}
</style>
